import { useEffect, useState } from 'react';
import { usePage } from '@inertiajs/react';

const renderIcon = (type) => {
	switch (type) {
		case 'alert-success':
			return (
				<span className="material-icons-outlined">
					check_circle
				</span>
			);
		case 'alert-error':
			return (
				<span className="material-icons-outlined">
					error
				</span>
			);
		default:
			return (
				<span className="material-icons-outlined">
					info
				</span>
			);
	}
};

export default function Toast({}) {
	const {
		toast,
		auth: { user }
	} = usePage().props;
	const [showToast, setShowToast] = useState(false);
	const delay = 5;

	useEffect(() => {
		if (toast === null) {
			setShowToast(false);
			return;
		}

		setShowToast(true);
		let timer = setTimeout(
			() => setShowToast(false),
			delay * 1000
		);

		return () => {
			clearTimeout(timer);
		};
	}, [toast]);

	return (
		<>
			{showToast && toast && (
				<div className="toast toast-center right-auto z-50">
					<div className={`alert ${toast.type}`}>
						{renderIcon(toast.type)}
						<span>{toast.message}</span>
					</div>
				</div>
			)}
		</>
	);
}
